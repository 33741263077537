function isMobileDevice() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 对于iPhone的检测，如果为iOS设备，且为移动设备，则返回true
    if (/iPad|Macintosh/.test(userAgent) && 'ontouchend' in document) {
        return true;
    }

    // 对于其他Android设备的检测
    if (/Android/i.test(userAgent)) {
        if (/mobile/i.test(userAgent)) {
            return true;
        }
    }

    // 对于其他移动设备的检测
    return /iemobile/i.test(userAgent) || /mobile/i.test(userAgent);
}

let isMobile = isMobileDevice();
export {isMobile};