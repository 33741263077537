import score_head from './积分管理小助手.png';
import {tool} from "../../tools/tools";
import {Button, Modal,} from "antd";
// import {isMobile} from "../album/album_tool";
import React from 'react';
import xiMp3 from './俊熙的下课时间到了.mp3';
import hanMp3 from './珺涵的下课时间到了.mp3';
import zhaoMp3 from './多多的下课时间到了.mp3';
import {StarOutlined} from "@ant-design/icons";
import "./css/电脑css/win_hub电脑css.css";

let playerRunning = false;


// 手动指定客户端 违反纪律
const href = window.location.href;
let isMobile = href.indexOf('computer') === -1;


// 开始上课 下一个小 结束上课 setT tool.get 名字 天内 {"diff: 申请 俊熙 使用积分 开启提醒 开始上课
class WinHubComponent extends React.Component {
    constructor(props) {
        super(props);
        this.request_state = {
            request_state_open: false, // 弹出框
            request_state_score: '', // 要申请的积分
        }
        // 自动播放声音及自动刷新
        this.auto_state = {
            autoSound: false, // 是否自动播放声音
            autoUpdateTime: null, // 自动更新数据的时间
            autoUpdateDelta: 3, // 自动更新的时间间隔
        }
        this.state = Object.assign({
            data: {},
            usernames: ['xi', 'han', 'zhao',],
            diff1: "-", // 洗刷进行中
            diff2: "-", // 使用积分进行中
            diff3: "-", // 上课进行中
            askShow: true,// 弹出询问播放声音的权限
        }, this.request_state, this.auto_state,);
        this.updateNowUserInfo = this.updateNowUserInfo.bind(this);
        this.onNext = this.onNext.bind(this);
        this.getReactHead = this.getReactHead.bind(this);
        this.getReactInfo = this.getReactInfo.bind(this);
        this.getReactButtons = this.getReactButtons.bind(this);
        this.onAction = this.onAction.bind(this);
        this.setDiff = this.setDiff.bind(this);
        this.getReactList = this.getReactList.bind(this);
        this.onSubmitRequest = this.onSubmitRequest.bind(this);
        this.getReactAsk = this.getReactAsk.bind(this);
        this.playerOrder = [];
    }

    componentDidMount() {
        const index = Math.floor(Math.random() * 3);
        let username = this.state.usernames[index];
        this.updateNowUserInfo(username);
    }

    // 更新 用户信息
    updateNowUserInfo(username, clearDiff = false,) {
        if (!username) username = this.state.data.username;

        let url = tool.path_join('api_v1/win_hub_data/', {user: username}, null,);
        tool.get({
            url: url,
            success: data => {
                this.setDiff();
                const state = {
                    data: data,
                };
                if (clearDiff) state['diff3'] = '-';
                this.setState(state);

                // setTimeout(() => {
                //     this.setState({diff3: '-',});
                // }, 200,);

            },
            error: data => tool.show_error(data),
        });
        this.setState({autoUpdateTime: new Date(),});
    }

    // 触发 下一个小朋友
    onNext() {
        this.setState({diff3: '-',});
        let username = this.state.data.username;
        let index = this.state.usernames.findIndex(item => item === username);
        index += 1;
        if (index > 2) index = index -= 3;
        let nextUsername = this.state.usernames[index];
        this.updateNowUserInfo(nextUsername);
    }

    // 触发 申请积分 提交
    onSubmitRequest() {
        let url = tool.path_join('api_v1/action_request_score/', {
            user: this.state.data.username,
            add: window.location.href,
        });
        tool.post({
            url: url,
            data: {request_score: this.state.request_state_score},
            success: _ => {
                tool.show_success('申请成功');
                this.setState(this.request_state);
                this.updateNowUserInfo();
            },
            error: data => tool.show_error(data),
        });
    }

    // 触发 开始一个动作
    onAction(event, order) {
        /*
        event-1: 洗刷任务
        event-2: 使用积分

        order-1: 设置开始时间
        order-2: 设置结束时间
        */
        tool.post({
            url: tool.path_join('api_v1/action/', {user: this.state.data.username,}),
            data: {
                event: event,
                order: order,
            },
            success: data => {
                let info = {1: '开始计时', 2: '结束计时',}[order];
                tool.show_success(info);
                this.updateNowUserInfo();
            },
            error: data => {
                this.updateNowUserInfo();
                tool.show_error(data);
            },
        });
    }

    // 触发 开始上课
    onStartClass() {
        this.player();
        this.setState({
            askShow: false,
            autoSound: true,
            run: true,
        });
        // this.player();
        tool.post({
            url: tool.path_join('api_v1/start_class/', {user: this.state.data.username,}),
            data: {},
            success: data => {
                tool.show_success('开始上课');
                this.updateNowUserInfo();
            },
            error: data => {
                this.updateNowUserInfo();
                tool.show_error(data);
            },
        });
    }

    // 触发 获得星星
    onCreateStar() {
        tool.post({
            url: tool.path_join('api_v1/submit_star/', {user: this.state.data.username,}),
            data: {},
            success: data => {
                tool.show_success('获得一颗小星星');
                this.updateNowUserInfo();
            },
            error: data => {
                this.updateNowUserInfo();
                tool.show_error(data);
            },
        });
    }

    // 触发 结束上课
    onEndClass() {
        tool.patch({
            url: tool.path_join('api_v1/end_class/1/', {user: this.state.data.username,}),
            data: {},
            success: data => {
                tool.show_success('结束上课');

                this.updateNowUserInfo();

                setTimeout(() => {
                    this.setState({diff3: '-',});
                }, 200,);
            },
            error: data => {
                this.updateNowUserInfo();
                tool.show_error(data);
            },
        });
    }

    // 触发 违反纪律
    onViolation(violation_type = 5) {
        tool.post({
            url: tool.path_join('api_v1/violation/', {user: this.state.data.username,}),
            data: {
                violation_type: violation_type,
            },
            success: data => {
                tool.show_success('违反纪律');
                this.updateNowUserInfo();
            },
            error: data => {
                this.updateNowUserInfo();
                tool.show_error(data);
            },
        });
    }

    // 工具 计时函数
    setDiff() {
        let intervalObj = setInterval(() => {
                let action_data = this.state.data.action_data ? this.state.data.action_data : [];
                let classData = this.state.data.class_data;

                let diff1 = '-';
                let diff2 = '-';
                let diff3 = '-';
                if (action_data.length === 0 && Object.keys(classData).length === 0) {
                    clearInterval(intervalObj);
                    this.setState({diff1: diff1, diff2: diff2,});
                    return;
                }

                // 动作计时
                for (const actionDatum of action_data) {
                    let event = actionDatum['event'];
                    let start = actionDatum['start'];
                    let startObj = new Date(start);
                    let startCurrent = startObj.getTime();

                    // 获取当前时间的时间戳
                    let nowObj = new Date();
                    const year = nowObj.getUTCFullYear();
                    const month = nowObj.getUTCMonth();
                    const day = nowObj.getUTCDate();
                    const hours = nowObj.getUTCHours();
                    const m = nowObj.getUTCMinutes();
                    const s = nowObj.getUTCSeconds();
                    nowObj = new Date(year, month, day, hours, m, s);
                    const nowCurrent = nowObj.getTime();

                    // 计算时间差值（单位：毫秒）
                    const difference = nowCurrent - startCurrent;
                    const minutes = Math.floor(difference / (1000 * 60));
                    const seconds = Math.floor((difference / 1000) % 60);
                    const diff = `${minutes}分${seconds}秒`;
                    if (event === 1) diff1 = diff;
                    if (event === 2) diff2 = diff;
                    this.setState({diff1: diff1, diff2: diff2,});
                }


                // 上课计时
                let start = classData['start'];
                let startObj = new Date(start);
                let startCurrent = startObj.getTime();

                // 获取当前时间的时间戳
                let nowObj = new Date();
                const year = nowObj.getUTCFullYear();
                const month = nowObj.getUTCMonth();
                const day = nowObj.getUTCDate();
                const hours = nowObj.getUTCHours();
                const m = nowObj.getUTCMinutes();
                const s = nowObj.getUTCSeconds();
                nowObj = new Date(year, month, day, hours, m, s);
                const nowCurrent = nowObj.getTime();

                // 计算时间差值（单位：毫秒）
                let difference = nowCurrent - startCurrent;
                if (difference < 6 * 60 * 60 * 1000) {
                    difference = difference + 8 * 60 * 60 * 1000;
                }


                const minutes = Math.floor(difference / (1000 * 60));
                const seconds = Math.floor((difference / 1000) % 60);
                if (start) {
                    diff3 = `${minutes}分${seconds}秒`;
                    this.setState({diff3: diff3,});

                    // 最后提醒时间点
                    let username = this.state.data.username;
                    const lastPlay_username = `lastPlay_${username}`
                    let lastPlay = tool.getAttribute(this, ['state', lastPlay_username,], 0);

                    for (let i = 50; i < 9999; i++) {
                        if (minutes === lastPlay) break;
                        if (minutes < i) continue;

                        lastPlay = i;
                        let info = {};
                        info[lastPlay_username] = i;
                        this.setState(info);

                        // 播放
                        const mapping = {
                            'xi': xiMp3,
                            'han': hanMp3,
                            'zhao': zhaoMp3,
                        };
                        this.playerOrder = [
                            mapping[username],
                        ];
                    }


                } else {
                    diff3 = '-';
                    this.setState({diff3: diff3,});
                }
            },
            1000
        );
    }

    // 工具 自动播放声音函数 自动更新数据
    player() {
        if (playerRunning) return;
        playerRunning = true;
        if (this.state.run) return;


        // 自动播放声音
        const autoPlayFunc = () => {
            if (!this.state.autoSound) return;
            for (const file of this.playerOrder) {
                // console.log('播放', file)
                const audioObj = new Audio(file);
                audioObj.play().then(() => {
                });
            }
            this.playerOrder = [];
        };

        // 自动更新数据
        const needUpdate = () => {
            const lastTime = this.state.autoUpdateTime;
            if (!lastTime) return true;
            const now = new Date();
            const delta = (now.getTime() - lastTime.getTime()) / 1000;
            return delta > 5;
        };
        const autoUpdateFunc = () => {
            if (!needUpdate()) return;
            this.updateNowUserInfo();
        };

        setInterval(() => {
            autoPlayFunc();
            autoUpdateFunc();
        }, 200);
    }

    // 组件 自动播放声音请求函数
    getReactAsk() {
        let react;

        react = <div>
            <Modal
                width={'80%'}
                // visible={this.state.askShow}
                visible={false}
                closeIcon={' '}
                footer={<div>

                    {/*开启提醒*/}
                    <Button
                        style={{fontSize: '300%', height: '80px',}}
                        onClick={() => {
                            this.player();
                            this.setState({
                                askShow: false,
                                autoSound: true,
                            });
                        }}
                    >
                        开启提醒
                    </Button>

                    {/*关闭提醒*/}
                    <Button
                        style={{fontSize: '300%', height: '80px',}}
                        onClick={() => {
                            this.player();
                            this.setState({
                                askShow: false,
                                autoSound: false,
                            });
                        }}
                    >
                        关闭提醒
                    </Button>

                </div>}
            >
                <div style={{fontSize: '300%',}}>
                    下课时是否需要开启语音提醒？
                </div>
            </Modal>
        </div>;
        return react;
    }

    // 组件 标题头
    getReactHead() {
        let react, img_style;
        img_style = {
            width: "100%",
            height: "auto",
        };
        react = <div>
            <img
                style={img_style}
                src={score_head}
                alt="积分管理小助手"
            />
        </div>
        return react;
    }

    // 组件 基本信息
    getReactInfo() {
        let cover = tool.path_join(this.state.data.cover);
        let name = this.state.data.name;

        // star
        const starCount = tool.getAttribute(this, ['state', 'data', 'star_data', 'star_count',], []);
        const username = tool.getAttribute(this, ['state', 'data', 'username',], '');

        // 满分记录
        const scores = tool.getAttribute(this, ['state', 'data', 'cycle_score_data', 'scores',], []);
        // const cycle_created_at = tool.getAttribute(this, ['state', 'data', 'cycle_score_data', 'cycle_created_at',]);
        const diff = tool.getAttribute(this, ['state', 'data', 'cycle_score_data', 'diff',]);
        const cycle_index = tool.getAttribute(this, ['state', 'data', 'cycle_score_data', 'cycle_index',]);
        const map = {
            1: '语',
            2: '数',
            3: '英',
            4: '坚持之星',
            0: '无满分记录',
        };
        let reactFullScoreInfo = [];
        if (scores.length === 0) scores.push({
            subject: 0,
            used: true,
        });
        for (const score of scores) {
            let subject = score['subject'];
            let subjectText = map[subject];
            let used = score['used'];
            reactFullScoreInfo.push(<div
                key={subject}
                style={{
                    display: 'inline-block',
                    color: used ? 'grey' : 'green',
                    marginRight: '5px',
                }}
            >
                {subjectText}
            </div>);
        }


        let scoreTotal = this.state.data.score_total;
        let cover_style = {
            width: "100%",
            height: "auto",
            borderRadius: '20px',
        };

        let td_style = {
            // border: 'red solid 1px',
            padding: "8px",
            textAlign: "left",
            width: "15%",
            height: "0",
            position: "relative",
            fontsize: '550%',
        };
        let td_style_x2 = {
            // border: 'red solid 1px',
            padding: "8px",
            textAlign: "left",
            width: "30%",
            height: "0",
            position: "relative",
            fontsize: '550%',
        };

        let td_style_1 = {
            fontSize: '350%',
        };
        let td_style_2 = {
            fontSize: '200%',
        };
        // let buttonStyle = {
        //     backgroundColor: "antiquewhite",
        //     height: "98%",
        //     width: "98%",
        //     fontSize: '350%',
        //     borderRadius: "15px",
        //     border: '0',
        //     whiteSpace: "pre-wrap",
        // }


        return <div>
            <div style={{
                display: "flex",
                flex: 1,
                // border: 'red 1px solid',
                fontSize: '450%',
                textAlign: 'center',
            }}>
                <div style={{
                    width: '33.3%',
                    backgroundColor: username === 'xi' ? 'lightskyblue' : '',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                }}
                     onClick={() => {
                         tool.show_info(`即将切换到俊熙`);
                         this.updateNowUserInfo('xi', true,);
                     }}
                >俊熙
                </div>


                <div style={{
                    width: '33.3%',
                    backgroundColor: username === 'han' ? 'lightskyblue' : '',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                }}
                     onClick={() => {
                         tool.show_info(`即将切换到珺涵`);
                         this.updateNowUserInfo('han', true,);
                     }}
                >珺涵
                </div>


                <div style={{
                    width: '33.3%',
                    backgroundColor: username === 'zhao' ? 'lightskyblue' : '',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                }}
                     onClick={() => {
                         tool.show_info(`即将切换到多多`);
                         this.updateNowUserInfo('zhao', true,);
                     }}
                >
                    多多
                </div>
            </div>
            <table style={{width: '100%',}}>
                <tbody>

                <tr>
                    {/*第1列*/}
                    <td style={td_style} colSpan={1}>
                        <img src={cover} alt={name} style={cover_style}/>
                    </td>

                    {/*第2列*/}
                    <td style={td_style_x2} colSpan={2}>
                        <div style={td_style_1}>名字: {name}</div>
                        <div style={td_style_1}>积分: {scoreTotal}</div>
                        {username !== 'zhao' ? <div style={td_style_2}>
                            第{cycle_index}周期:

                            {reactFullScoreInfo}
                            <div>
                                ({diff}天内点亮
                                <span style={{
                                    color: 'green',
                                }}>语数英</span>勋章,可兑换礼物.)
                            </div>
                        </div> : null}
                        {username === 'zhao' ? <div style={td_style_2}>
                            星星数量:
                            <div style={{
                                color: 'pink',
                                display: 'inline-block',
                                marginLeft: '50px',
                            }}>
                                {starCount}
                                <StarOutlined/>
                            </div>
                            <div style={{
                                // fontSize: '80%',
                            }}>
                                ({diff}天内再集{40 - starCount}颗<StarOutlined/>可获得
                                <span style={{
                                    color: 'green',
                                }}>坚持之星</span>
                                勋章,过期清零)
                            </div>

                        </div> : null}
                    </td>

                    {/*第3列*/}
                    {/*<td style={td_style}>*/}
                    {/*-*/}
                    {/*<button style={buttonStyle} onClick={this.onNext}>下一个<br/>小朋友</button>*/}
                    {/*</td>*/}

                </tr>
                </tbody>
            </table>
        </div>
    }

    // 组件 操作区
    getReactButtons() {
        const username = tool.getAttribute(this, ['state', 'data', 'username',], null);
        let buttonStyle = {
            // width: '90%',
            height: '20%',
            fontSize: '350%',
            margin: '10px',
            backgroundColor: 'lightskyblue',
            border: "0",
            borderRadius: '5px',
            cursor: 'pointer',
            // marginLeft: '5%',
        };
        const buttonStyle2 = {
            ...buttonStyle,
            backgroundColor: 'white',
        };


        const tdStyle = {
            border: '1px darkgray solid',
        };
        let table;
        table = <table style={{
            // border: 'red solid 1px',
        }}>
            <tbody>
            <tr>

                {/*第1列:使用积分*/}
                <td style={tdStyle}>
                    <table>
                        <tbody>

                        {/*第1行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.setState({request_state_open: true})}
                                >申请积分
                                </button>

                                <Modal
                                    open={this.state.request_state_open}
                                    visible={this.state.request_state_open}
                                    closeIcon={" "}
                                    width={isMobile ? '100%' : '60%'}
                                    style={{maxWidth: '90%', maxHeight: '90%', minWidth: '300px'}}
                                    footer={<div style={{}}>
                                        <Button
                                            type={'primary'}
                                            onClick={this.onSubmitRequest}
                                            style={{
                                                height: '90px',
                                                fontSize: '250%',
                                                marginRight: '20px',
                                            }}
                                        >申请
                                        </Button>

                                        <Button
                                            type={'primary'}
                                            onClick={() => this.setState({request_state_open: false})}
                                            style={{
                                                height: '90px',
                                                fontSize: '250%',
                                                marginRight: '20px',
                                            }}
                                        >取消
                                        </Button>
                                    </div>}
                                >

                                    <div
                                        style={{
                                            display: 'inline-block',
                                            flex: 1,
                                            borderRadius: '2px',
                                            // border: 'grey solid 1px',
                                            backgroundColor: '',
                                            cursor: "pointer",
                                            textAlign: 'center',
                                            fontSize: isMobile ? '250%' : '120%',
                                        }}
                                    >
                                        申请积分
                                        <input
                                            onChange={event => this.setState({request_state_score: event.target.value})}
                                            value={this.state.request_state_score}
                                        ></input>

                                        {/*<InputNumber*/}
                                        {/*    inputFontSize={500}*/}
                                        {/*    style={{*/}
                                        {/*        fontSize: isMobile ? '250px' : '120px',*/}
                                        {/*    }}*/}
                                        {/*    addonBefore={'申请积分'}*/}
                                        {/*></InputNumber>*/}
                                    </div>
                                </Modal>

                            </td>
                        </tr>

                        {/*第2行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onAction(2, 1)}>
                                    使用积分
                                </button>
                            </td>
                        </tr>

                        {/*第3行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onAction(2, 2)}>
                                    结束使用
                                </button>
                            </td>
                        </tr>

                        {/*第4行*/}
                        <tr style={{textAlign: 'center',}}>
                            <td>
                                <button
                                    style={buttonStyle2}>
                                    {this.state.diff2}
                                </button>
                            </td>

                        </tr>

                        </tbody>
                    </table>

                </td>

                {/*第2列:开始上课*/}
                <td style={tdStyle}>
                    <table>
                        <tbody>

                        {/*第1行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onStartClass()}>
                                    开始上课
                                </button>
                            </td>
                        </tr>

                        {/*第2行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onEndClass()}>
                                    结束上课
                                </button>
                            </td>
                        </tr>

                        {/*第3行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onViolation()}>
                                    违反纪律
                                </button>
                            </td>
                        </tr>

                        {/*第4行*/}
                        <tr style={{textAlign: 'center',}}>
                            <td>
                                <button
                                    style={buttonStyle2}>
                                    {this.state.diff3}
                                </button>
                            </td>

                        </tr>

                        </tbody>
                    </table>

                </td>

                {/*第3列:获得星星*/}
                {username === 'zhao' ? <td style={tdStyle}>
                    <table>
                        <tbody>

                        {/*第1行*/}
                        <tr>
                            <td>
                                <button
                                    style={buttonStyle}
                                    onClick={() => this.onCreateStar()}>
                                    获得星星
                                </button>
                            </td>
                        </tr>

                        {/*第2行*/}
                        <tr style={{textAlign: 'center',}}>
                            <td>
                                <button
                                    style={buttonStyle2}>
                                    -
                                </button>
                            </td>

                        </tr>

                        {/*第3行*/}
                        <tr style={{textAlign: 'center',}}>
                            <td>
                                <button
                                    style={buttonStyle2}>
                                    -
                                </button>
                            </td>

                        </tr>

                        {/*第4行*/}
                        <tr style={{textAlign: 'center',}}>
                            <td>
                                <button
                                    style={buttonStyle2}>
                                    -
                                </button>
                            </td>

                        </tr>

                        </tbody>
                    </table>

                </td> : null}

                {/*第4列*/}
                <td>

                </td>
            </tr>
            </tbody>


        </table>
        return table;
    }

    // 组件 列表区
    getReactList() {

        let reactActions = [];
        let actions = tool.getAttribute(this, ['state', 'data', 'action_list',], []);
        let lineStyle = {
            width: '90%',
            fontSize: '260%',
            // backgroundColor: 'lightgreen',
            backgroundColor: 'lightyellow',
            margin: 'auto',
            borderRadius: '15px',
            marginTop: '5px',
            marginBottom: '5px',

            position: 'relative', // 使父容器相对定位
        };
        for (const action of actions) {
            let actionId = action['id'];
            let start = action['start'];
            let remark = action['remark'];
            let event = action['event'];
            let score = action['score'];
            let status = action['status'];
            let style = Object.assign({}, lineStyle);
            if (status !== 1) style['backgroundColor'] = 'grey';
            reactActions.push(<div key={actionId} style={style}>
                {tool.utc_to_china(start)} {remark}
                <div style={{
                    backgroundColor: score >= 0 ? 'greenyellow' : 'lightpink',
                    borderRadius: '10px',
                    height: '80%',
                    position: 'absolute',
                    right: '20px',
                    width: '10%',

                    top: '50%',
                    transform: 'translateY(-50%)',
                }}>
                    {event !== 6 ? score : <span><StarOutlined/>+1</span>}
                </div>
            </div>);
        }
        return <div style={{
            marginTop: '10px',
            textAlign: 'center',
            // border: 'red solid 1px',
            backgroundColor: 'lightgray',
            borderRadius: '10px',
            padding: '10px',
            height: '800px',
            overflow: 'auto',
        }}>
            {reactActions}
        </div>;
    }

    render() {
        let reactHead = this.getReactHead();
        let reactInfo = this.getReactInfo();
        let reactButtons = this.getReactButtons();
        let reactList = this.getReactList();
        let reactAsk = this.getReactAsk();
        let class1 = '';
        let class2 = '';
        if (!isMobile) {
            class1 = 'win_hub电脑css_浏览器边框';
            class2 = 'win_hub电脑css_程序边框';
        }

        return <div className={class1}>
            <div className={class2}>
                {reactAsk}
                {reactHead}
                {reactInfo}
                {reactButtons}
                {reactList}
            </div>
        </div>
    }

}

export {
    WinHubComponent
};
