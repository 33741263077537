import React from "react";
import {connect} from 'react-redux';
import {tool} from "../../tools/tools";

class EatImageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            res: null,
        };
    }

    downloadFile(url) {
        // 创建一个 <a> 元素
        const link = document.createElement('a');
        // 设置 <a> 元素的 href 属性为传入的 URL
        link.href = url;
        // 设置 download 属性，使浏览器下载文件而不是打开
        link.download = '';
        // 将 <a> 元素添加到文档中
        document.body.appendChild(link);
        // 模拟点击 <a> 元素以触发下载
        link.click();
        // 下载完成后，从文档中移除 <a> 元素
        document.body.removeChild(link);
    }

    handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const data = new FormData();
            data.append('file', file);
            tool.post({
                url: tool.path_join('api_v1/upload_file/'),
                data: data,
                success: data => {
                    const cloudId = tool.getAttribute(data, ['cloud_id']);

                    tool.post({
                        url: tool.path_join('api_v1/eat_image/'),
                        data: {
                            cloud_id: cloudId,
                        },
                        success: data => {
                            this.setState({
                                res: data['res'],
                            });
                            this.downloadFile(tool.path_join(data['res']))
                        },
                        error: data => {
                            tool.show_error(data);
                        },
                    });
                },
                error: data => {
                    console.log('error-->', data)
                },
                contentType: false,
                processData: false,
            });
        }
    }

    render() {
        let img = null;
        if (this.state.res) {
            let url = tool.path_join(this.state.res);
            img = <img alt={''} src={url}/>;
        }

        return <div>
            制作一个小人吃掉头像的gif
            <br/>
            直接选择文件后等待就好了,制作完自动下载,大概3秒
            <br/>
            <button onClick={() => this.fileInput.click()}>选择图片</button>
            <input
                type="file"
                accept="image/*"
                style={{display: 'none'}}
                ref={(input) => this.fileInput = input}
                onChange={this.handleFileSelect}
            />

            {img}
        </div>;
    }
}

function mapStateToArt(state) {
    return {}
}

const EatImage = connect(mapStateToArt)(EatImageComponent);

export {
    EatImage,
};